@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html,body { height: 100%; margin: 0px; padding: 0px; }
#full { background: #0f0; height: 100% }

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}
#force{
  position: absolute;
}
canvas{
  position:absolute;
  left:0;
  top:0;
  z-index:-1;
}
.App-header {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.octanceTitle{
  color:#ff6978 ;
  font-size: calc(60px + 2vmin);
  font-family: 'Montserrat';
  font-weight: bold;
  margin: initial;
}
.pointsTitle{
  color:black ;
  font-size: calc(20px + 2vmin);
  font-family: 'Montserrat';
  font-weight: bold;
  margin: initial;
}
.attraction{ 
  font-size: calc(15px + 2vmin);
  font-family: 'Montserrat';
  margin-top: 5vh;
}
.attractionLastLine{ 
  font-size: calc(10px + 2vmin);
  font-family: 'Montserrat';
  margin-top: 20vh;
}
.attractionTag{
  color:#ff6978 ;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: calc(60px + 2vmin);
}
.attractionTagReadme{
  color:#ff6978 ;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: calc(20px + 2vmin);
}
.tag{
  color:#ff6978 ;
  font-family: 'Montserrat';
  font-weight: bold;
}
.aiTitle{
  color:black ;
}
.empowerTitle{
  color:black;
  font-size: calc(10px + 2vmin);
  margin: initial;
  font-family: 'Montserrat';
  font-weight: bold;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}
.mailto{
  color: unset;
  text-decoration-thickness: from-font;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}